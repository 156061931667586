// extracted by mini-css-extract-plugin
export var alignLeft = "y_pB d_fp d_bG d_dv";
export var alignCenter = "y_bP d_fq d_bD d_dw";
export var alignRight = "y_pC d_fr d_bH d_dx";
export var verticalSolidComponent = "y_ts";
export var compContent = "y_tm d_r";
export var normal = "y_tt d_Z d_cb";
export var reverse = "y_tv d_Z d_bK";
export var last = "y_tn";
export var empty = "y_tw d_r";
export var empty2 = "y_tx d_bw";
export var borderRight = "y_ty d_b8 d_c1";
export var borderLeft = "y_tz d_b9 d_c1";