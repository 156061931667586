// extracted by mini-css-extract-plugin
export var alignDiscLeft = "n_qk d_fp d_bG d_dv";
export var alignLeft = "n_pB d_fp d_bG d_dv";
export var alignDiscCenter = "n_ql d_fq d_bD d_dw";
export var alignCenter = "n_bP d_fq d_bD d_dw";
export var alignDiscRight = "n_qm d_fr d_bH d_dx";
export var alignRight = "n_pC d_fr d_bH d_dx";
export var footerContainer = "n_qn d_dW d_bW";
export var footerContainerFull = "n_qp d_dT d_bW";
export var footerHeader = "n_kf d_kf";
export var footerTextWrapper = "n_qq d_w";
export var footerDisclaimerWrapper = "n_km d_km d_ck";
export var badgeWrapper = "n_qr d_w d_bz d_bP d_bF d_bJ";
export var verticalTop = "n_qs d_bz d_bJ d_bN d_bL";
export var wide = "n_qt d_cy";
export var right = "n_qv d_bK";
export var line = "n_fk d_fl d_cv";
export var badgeDisclaimer = "n_qw d_bC d_bP d_bJ";
export var badgeContainer = "n_qx d_bz d_bH d_bP";
export var badge = "n_qy";
export var padding = "n_qz d_c7";
export var end = "n_qB d_bH";
export var linkWrapper = "n_qC d_dB";
export var link = "n_mZ d_dB";
export var colWrapper = "n_qD d_cx";
export var consent = "n_f d_f d_bC d_bP";
export var disclaimer = "n_qF d_bz d_bJ";
export var media = "n_qG d_bx d_dy";
export var itemRight = "n_qH";
export var itemLeft = "n_qJ";
export var itemCenter = "n_qK";
export var exceptionWeight = "n_qL q_rd";