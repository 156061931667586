// extracted by mini-css-extract-plugin
export var alignLeft = "x_pB d_fp d_bG d_dv";
export var alignCenter = "x_bP d_fq d_bD d_dw";
export var alignRight = "x_pC d_fr d_bH d_dx";
export var verticalDottedFirstComponent = "x_tj";
export var wrap = "x_tk d_bJ d_cb";
export var topPadding = "x_tl d_cX";
export var compContent = "x_tm d_w";
export var last = "x_tn";
export var box = "x_tp d_cr";
export var boxWrapper = "x_tq d_w d_bz";
export var boxWrapperFirst = "x_tr d_w d_bz";