// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "t_sL d_gv d_cs";
export var heroHeaderCenter = "t_gw d_gw d_cs d_dw";
export var heroHeaderRight = "t_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "t_sM d_gr d_cw";
export var heroParagraphCenter = "t_gs d_gs d_cw d_dw";
export var heroParagraphRight = "t_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "t_sN d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "t_sP d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "t_sQ d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "t_sR d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "t_sS d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "t_rG q_rG";
export var heroExceptionNormal = "t_rH q_rH";
export var heroExceptionLarge = "t_rJ q_rJ";
export var Title1Small = "t_rh q_rh q_qN q_qP";
export var Title1Normal = "t_rj q_rj q_qN q_qQ";
export var Title1Large = "t_rk q_rk q_qN q_qR";
export var BodySmall = "t_rw q_rw q_qN q_q5";
export var BodyNormal = "t_rx q_rx q_qN q_q6";
export var BodyLarge = "t_ry q_ry q_qN q_q7";